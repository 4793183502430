<template>
  <div>
    <div v-if="items && items.length > 0">
      <div class="p-3" v-for="value in items" :key="value[0].order_number">
        <b-row class="p-2">
          <b-col>
            <h5>
              {{ $t('Myorder.order_number') }}:
              <span class="span--order-number">{{
                value[0].order_number
              }}</span>
            </h5>
          </b-col>
          <b-col
            ><h5>
              {{ $t('Myorder.tourCode') }}:
              <span class="span--order-number">{{ value[0].packageCode }}</span>
            </h5></b-col
          >
          <b-col
            ><h5>
              {{ $t('Myorder.order_time') }}: {{ value[0].created_at }}
            </h5></b-col
          >
        </b-row>
        <b-table
          responsive
          bordered
          :items="value"
          :fields="fields"
          class="text-center mr-2 mb-4"
        >
          <template #cell(order_information)="data">
            <div class="text-left">
              {{ data.value }}
            </div>
            <div
              class="div--text-info pt-3 text-left"
              v-html="value[0].ticket"
            ></div>
          </template>
          <template #cell(order_type)="data">
            <div class="text-left">
              <div>{{ $t('Myorder.Package_Tour') }}</div>
            </div>
          </template>
          <template #cell(tour_date)="data">
            <div class="text-left">
              <div>{{ $t('Myorder.departure_date') }}：</div>
              <span>{{ data.value }}</span>
            </div>
          </template>
          <template #cell(payment_amount)="data">
            <span v-if="value[0].total_amount != 0">{{
              formatCurrency(value[0].total_amount, currency)
            }}</span>
            <span v-else>{{
              formatCurrency(data.value ? data.value : 0, currency)
            }}</span>
            <br />
            <span
              ><span class="text-primary">{{ $t('Myorder.paid') }}: </span>
              <br />{{
                formatCurrency(value[0].deposit_amount, currency)
              }}</span
            ><br />
            <span
              ><span class="text-primary">
                {{ $t('Myorder.Banlance_amout') }}</span
              ><br /><span
                class="text-danger"
                v-bind:id="`balance_amount_${value[0].id_booking}`"
                :balance="value[0].balance_amount"
                >{{ formatCurrency(value[0].balance_amount, currency) }}</span
              ></span
            >
          </template>
          <template #cell(operation)="data">
            <div>
              <div class="text-info">
                <router-link :to="{ path: `/order-detail/${value[0].id}` }">{{
                  $t('Myorder.view')
                }}</router-link>
              </div>
              <div
                class="text-info"
                v-if="userType !== 'USER' && userType !== 'STAFF'"
              >
                <a
                  href="javascript:void(0)"
                  @click="showModalCancel(value[0]._id)"
                  >{{ $t('Myorder.cancel') }}</a
                >
              </div>
              <div class="text-info" v-if="userType !== 'USER'">
                <router-link
                  v-if="
                    moment() <
                    moment(value[0].created_at).add(
                      numberOfDaysForOrderEditing,
                      'days',
                    )
                  "
                  :to="{ path: `/edit-order/${value[0].id}` }"
                  >{{ $t('Myorder.edit') }}</router-link
                >
                <a class="text-info" v-else disabled>{{
                  $t('Myorder.edit')
                }}</a>
              </div>
              <div class="text-info" v-if="data.value.shouldGoPay">
                <a
                  href="javascript:void(0);"
                  v-on:click="openGoPayModal(value[0])"
                  >{{ $t('Myorder.go_pay') }}</a
                >
              </div>
            </div>
          </template>
          <template #cell(order_status)="data">
            <div v-if="data.value == -1">
              <span>{{ $t('Myorder.partial') }}</span>
            </div>
            <div v-else-if="data.value == 1">
              <span class="text-danger">{{ $t('Myorder.unpaid') }}</span>
            </div>
            <div v-else-if="data.value == 2">
              <span>{{ $t('Myorder.cancel') }}</span>
            </div>
            <div v-else>
              <span>{{ $t('Myorder.paid') }}</span>
            </div>
          </template>
        </b-table>
      </div>
      <div v-if="loading == true" class="loader centered"></div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalPageOfAll"
        :per-page="perPage"
        @change="openPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>
    <div v-else class="w-100">
      <b-container v-if="loading">
        <Spinner />
      </b-container>
      <p
        class="text-center my-4"
        v-if="items && items.length == 0 && loading == false"
      >
        <img
          class="mw-100"
          src="../../assets/img/search_not_found.png"
          alt="Image"
        />
      </p>
    </div>
    <b-modal
      ref="modal-gopay-bk"
      id="modal-gopay-bk"
      :hide-footer="true"
      v-if="items.length > 0"
    >
      <div
        class="row card shadow-none border-0 pb-5 mb-3 card-body px-lg-5 py-lg-3 modal-from-tour"
      >
        <div class="text-center pb-3">
          <h5>
            {{ $t('Myorder.payment_type') }}
            <span
              v-if="selectedOrderToPay && selectedOrderToPay.order_status == -1"
            >
              {{ $t('Myorder.partial') }}
            </span>
            <span
              v-else-if="
                selectedOrderToPay &&
                selectedOrderToPay.firstSelectedPaymentTransaction == 'full'
              "
            >
              {{ $t('Myorder.full_payment') }}
            </span>
            <span v-else>{{ $t('Myorder.deposit_payment') }}</span>
          </h5>
        </div>
        <div v-if="selectedOrderToPay" class="px-5">
          <div
            class="d-flex flex-column flex-md-row pb-2 justify-content-between"
          >
            <div>
              {{ $t('Myorder.tourCode') }}:
              <span class="text-primary font-weight-bold">
                {{ selectedOrderToPay.packageCode }}
              </span>
            </div>
            <div>
              {{ $t('Myorder.title') }}:
              {{ selectedOrderToPay.title }}
            </div>
          </div>
          <div class="d-flex flex-column flex-md-row justify-content-between">
            <div>
              {{ $t('Myorder.order_number') }}:
              <span class="text-info">
                {{ selectedOrderToPay.order_number }}</span
              >
            </div>
            <div>
              {{ $t('Myorder.departure_date') }}:
              <span class="text-info"> {{ selectedOrderToPay.tour_date }}</span>
            </div>
          </div>
        </div>
        <form role="form" class="px-5 pt-4 modal-form">
          <b-form-group class="paymentAmountCustomer-group">
            <b-input-group>
              <b-form-input
                :disabled="
                  selectedOrderToPay &&
                  selectedOrderToPay.firstSelectedPaymentTransaction === 'full'
                "
                v-validate="{
                  required: true,
                  numeric: true,
                  integer,
                  min: 1,
                  greaterOrEqualThanFour,
                }"
                name="amount"
                :state="validateState('amount')"
                :placeholder="$t('booking.Payment_amount_text')"
                v-model="paymentAmountCustomer"
                aria-describedby="input-amount-Invalid"
              ></b-form-input>
              <b-form-invalid-feedback id="input-amount-Invalid">
                <span class="error">{{
                  $t('booking.checkDepositPayment') + balancePaymentAmount
                }}</span>
                <span class="error" v-if="depositPayment == 0">{{
                  $t('booking.minDepositPayment') + minDepositPayment
                }}</span>
              </b-form-invalid-feedback>
            </b-input-group>
            <span class="error"></span>
          </b-form-group>
        </form>
      </div>
      <hr />
      <div class="row text-center">
        <div class="col-6">
          <b-button
            variant="secondary"
            @click="$bvModal.hide('modal-gopay-bk')"
            >{{ $t('Myorder.cancel') }}</b-button
          >
        </div>
        <div class="col-6">
          <b-button variant="warning" @click="submitGopay()" v-if="!busy"
            >{{ $t('Myorder.go_pay') }}
          </b-button>
          <b-button v-else variant="warning" disabled>
            <b-spinner small type="grow"></b-spinner>
            {{ $t('Register_and_Login.loading') }}...
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-cancel-bk" id="modal-cancel-bk" :hide-footer="true">
      <div class="row text-center justify-content-center">
        <img
          class=""
          src="/icon/icon-hint-red@2x.png"
          style="width: 60px"
          alt="Image"
        /><br />
        <p class="my-4 text-center w-100">{{ $t('Myorder.cancel_order') }}</p>
      </div>
      <hr />
      <div class="row text-center">
        <div class="col-6">
          <b-button
            variant="secondary"
            @click="$bvModal.hide('modal-cancel-bk')"
            >{{ $t('Myorder.cancel') }}</b-button
          >
        </div>
        <div class="col-6">
          <b-button variant="warning" @click="submitCancelOrder()" v-if="!busy"
            >{{ $t('Myorder.confirm') }}
          </b-button>
          <b-button v-else variant="warning" disabled>
            <b-spinner small type="grow"></b-spinner>
            {{ $t('Register_and_Login.loading') }}...
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      centered
      size="lg"
      ref="modal-message-bk"
      id="modal-message-bk"
      :hide-footer="true"
    >
      <div class="row text-center justify-content-center">
        <img
          class=""
          src="/icon/icon-hint-red@2x.png"
          style="width: 60px"
          alt="Image"
        /><br />
        <p class="my-4 text-center w-100">
          {{ $t('Myorder.cancel_order_message') }}
        </p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import Spinner from '../spinner/Spinner';
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      loading: false,
      items: [],
      perPage: 1,
      orderNumber: null,
      orderIDSelected: null,
      paymentAmountCustomer: 0,
      depositPayment: 0,
      minDepositPayment: 0,
      balancePaymentAmount: 0,
      paymentBalane: null,
      totalOrder: null,
      currentPage: 1,
      totalPageOfAll: 1,
      orderNumberStatus: null,
      orderId: null,
      selectedOrderToPay: null,
      userType: null,
      //loadding button
      busy: false,
      numberOfDaysForOrderEditing: 0,
      currency: 'HK$',
    };
  },
  props: {
    status: {
      type: [String, Number],
      default: undefined,
    },
    fields: {
      type: [Array],
      default: () => [],
    },
  },
  computed: {
    dataOrders() {
      const data = this.$store.getters['orderModule/getOrders'];
    },
  },
  created() {
    this.$validator.extend('greaterOrEqualThanFour', {
      getMessage: (field) => field + ' needs to be >= 4.',
      validate: (value) => {
        if (
          this.selectedOrderToPay.firstSelectedPaymentTransaction === 'full'
          //check type payment full
        ) {
          if (value == this.selectedOrderToPay.balance_amount) return true;
        } else {
          //check type payment deposit
          if (this.depositPayment == 0) {
            //if there ism't a deposit payment
            if (
              value == this.minDepositPayment ||
              value == this.selectedOrderToPay.balance_amount
            )
              return true;
          } else {
            //if there is a deposit payment
            if (value == this.selectedOrderToPay.balance_amount) return true;
          }
        }
        return false;
      },
    });
  },
  mounted() {
    // this.numberOfDaysForOrderEditing =
    //   this.$store.getters['parametersModule/getParameters']
    //     .numberOfDaysForOrderEditing + 1;
    this.$store.dispatch('parametersModule/getMessageConfig').then((data) => {
      this.numberOfDaysForOrderEditing = data.numberOfDaysForOrderEditing + 1;
    });
    this.getOrder(this.status);
    this.userType = this.$store.getters['authModule/getUserType'];
  },
  methods: {
    alert(value) {
      alert(value);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    openPage: function (value) {
      console.log(value);
      this.items = [];
      this.currentPage = value;
      this.loading = true;
      this.getOrder(this.status);
    },
    amountAsNumber: function (amount) {
      // Remove currency symbol and commas
      const text=String(amount)
      const amountWithoutCurrency = text
        .replace(this.currency, '')
        .replace(/,/g, '');
      // Parse the remaining string into a number
      return Number(amountWithoutCurrency);
    },
    submitGopay() {
      this.busy = true;

      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        const dataPayment = {
          amount: this.amountAsNumber(this.paymentAmountCustomer),
          orderNumber: this.selectedOrderToPay.order_number,
          orderId: this.selectedOrderToPay.id,
          ticSwitch: this.selectedOrderToPay.ticSwitch,
          contactEmail: this.selectedOrderToPay.contactEmail,
        };
        sessionStorage.setItem('dataPayment', JSON.stringify(dataPayment));
        // var paymentUrl = window.location.origin;
        // window.location.assign(paymentUrl + '/#/payment');
        this.$router.push('/payment');
      });
      this.busy = false;
    },

    groupByKey: function (array, key) {
      return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {
          [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
      }, {});
    },
    openGoPayModal: function (value) {
      this.selectedOrderToPay = value;
      this.paymentAmountCustomer = 0;
      if (this.selectedOrderToPay.firstSelectedPaymentTransaction == 'full') {
        this.paymentAmountCustomer = this.formatCurrency(
          this.selectedOrderToPay.balance_amount,
          this.currency,
        );
        // this.paymentAmountCustomer = this.selectedOrderToPay.balance_amount;
      } else {
        this.paymentAmountCustomer =
          this.selectedOrderToPay.order_status == -1
            ? this.selectedOrderToPay.balance_amount
            : this.selectedOrderToPay.minDepositPayment;
      }

      this.depositPayment = this.selectedOrderToPay.deposit_amount;
      this.minDepositPayment = this.selectedOrderToPay.minDepositPayment;
      this.balancePaymentAmount = this.selectedOrderToPay.balance_amount;
      // paymentAmountCustomer

      this.$refs['modal-gopay-bk'].show();
    },

    formatCurrency(amount, currency) {
      const formattedNumber = amount.toLocaleString('en', {
        minimumFractionDigits: 0,
      });
      return `${currency}${formattedNumber}`;
    },

    getOrder(status) {
      let text1 = this.$t('booking.adultPrice');
      let text2 = this.$t('booking.memberPrice');
      let text3 = this.$t('booking.babyPrice');
      let text4 = this.$t('booking.childrenPrice');
      this.loading = true;
      this.items = [];
      this.$store
        .dispatch('orderModule/getOrdersResponse', {
          condition: {
            status,
          },
          pageSize: 10,
          pageNum: this.currentPage,
        })
        .then(() => {
          const getOrders = this.$store.getters['orderModule/getOrders'];
          const data = getOrders.rows || [];
          const totalCount = getOrders.totalCount;
          const pages = totalCount / 10;
          const pagesRound = parseInt(Math.round(totalCount / 10));
          if (pages > pagesRound) {
            this.totalPageOfAll = pagesRound + 1;
          } else {
            this.totalPageOfAll = Math.round(pages);
          }
          this.loading = false;
          data.forEach((element) => {
            // let members = element.members;
            let adultCount = 0;
            let memberCount = 0;
            let babyCount = 0;
            let childrenCount = 0;
            // for (var i = 0; i < members.length; i++) {
            //   if (members[i].memberType == 'adult') {
            //     adultCount++;
            //   }
            //   if (members[i].memberType == 'member') {
            //     memberCount++;
            //   }
            //   if (members[i].memberType == 'baby') {
            //     babyCount++;
            //   }
            //   if (members[i].memberType == 'children') {
            //     childrenCount++;
            //   }
            // }
            let countTicket = '';

            if (adultCount > 0) {
              countTicket = text1 + ' x' + adultCount + '<br/>';
            }
            if (memberCount > 0) {
              countTicket = text2 + ' x' + memberCount + '<br/>';
            }
            if (babyCount > 0) {
              countTicket = text3 + ' x' + babyCount + '<br/>';
            }
            if (childrenCount > 0) {
              countTicket = text4 + ' x' + childrenCount;
            }
            let payment_amount = 0;
            if (element.status == 0) {
              if (element.depositAmount < element.totalAmount) {
                payment_amount = element.totalAmount;
              } else {
                payment_amount = element.depositAmount;
              }
            } else if (element.status == -1) {
              if (element.paymentAmount == 0) {
                payment_amount = element.depositAmount + element.paymentAmount;
              } else {
                payment_amount = element.depositAmount;
              }
            } else if (element.status == 1) {
              payment_amount = element.depositAmount + element.paymentAmount;
            } else {
              payment_amount = element.paymentAmount;
            }
            this.items.push([
              {
                id: element.id,
                order_number: element.orderNumber,
                minDepositPayment: element.minDepositPayment,
                packageCode: element.packageCode,
                shortPackageDescription: element.shortPackageDescription,
                order_information: element.shortPackageDescription,
                type: 'Tour package',
                tour_date: moment(String(element.departureDate)).format(
                  'YYYY-MM-DD',
                ),
                deposit_amount: element.paid,
                balance_amount: element.balanceAmount,
                total_amount: element.grandTotal,
                order_status: element.status,
                operation: {
                  shouldCancel: element.shouldCancel,
                  shouldGoPay: element.shouldGoPay,
                },
                created_at: moment(String(element.createdAt)).format(
                  'YYYY-MM-DD',
                ),
                code: element.packageCode,
                orderNumber: element.orderNumber,
                id_booking: element.id,
                ticket: countTicket,
                contactEmail: element.contactEmail,
                ticSwitch: element.ticSwitch,
                title: element.title,
                firstSelectedPaymentTransaction:
                  element.firstSelectedPaymentTransaction,
              },
            ]);
          });
        })
        .catch(function (error) {
          this.loading = false;
          // this.axiosError = error;
        });
    },

    showModalCancel(orderId) {
      this.orderId = orderId;
      this.$refs['modal-cancel-bk'].show();
    },

    submitCancelOrder() {
      this.busy = true;
      this.$refs['modal-cancel-bk'].hide();

      this.$store
        .dispatch('orderModule/cancelOrderById', {
          id: this.orderId,
          status: 2,
        })
        .then((res) => {
          this.$refs['modal-message-bk'].show();
          setTimeout(() => {
            this.$refs['modal-message-bk'].hide();
            location.reload();
          }, 3000);
        })
        .catch((error) => {
          this.$vToastify.error({
            body: error,
            defaultTitle: false,
          });
          this.busy = false;
        });
    },
  },
};
</script>
<style>
span.error {
  color: red;
}
ul.pagination {
  justify-content: center;
}
.loading {
  /*display: none;*/
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  /* left, top and position just for the demo! */
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99999999;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  color: darkred;
}
</style>
